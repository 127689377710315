class ZIDXAccountPages implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountPagesContainer";
    }
    getMatchURL(){
        return "/account/pages";
    }
    render(){
        // console.log("pages");
    }
}